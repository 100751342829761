import React, { useRef, useState, useEffect } from 'react';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import styled, { keyframes } from "styled-components";
import Accordion from "../Accordion";
import CloseButtonImage from '../../assets/close.webp';

const Faqs = ({ onClose }) => {
  const ref = useRef(null);
  const [faqsData, setFaqsData] = useState(null);

  gsap.registerPlugin(ScrollTrigger);


  useEffect(() => {
    // Simulate API delay to show loading animation
    setTimeout(() => {
      setFaqsData(true);
    }, 2000);
  }, []);
  
  return (
    <>
      <PopupOverlay />
      <PopupContainer ref={ref} id="faqs">
        <Popup>
          {!faqsData ? (
            <Loading>Loading FAQS...</Loading>
          ) : (
            <>
            <PopupHeader><PopUpTitle>Faqs</PopUpTitle>
              <H2 > <CloseButton  onClick={onClose}></CloseButton>
              </H2>
            </PopupHeader>
              <AccordionContainer>
            <Accordion title="What is the main objective of HI/LO BALLERZ?">
              The main objective of HI/LO BALLERZ is to correctly predict whether the next card in a series of 5 cards will be higher or lower than the previous card. Players place an initial bet, and if they make the correct prediction, their winnings will double. After each round they have a chance of cashing out!
            </Accordion>
            <Accordion title="What wallets can be used to place bets in HI/LO BALLERZ?">
              Players can place bets using blade wallet and hashpackwallet.
            </Accordion>
            <Accordion title="What happens when a player makes a correct prediction in HI/LO BALLERZ?">
              When a player makes a correct prediction in HI/LO BALLERZ, their winnings will double, and the next bet will be twice the previous bet. The player can continue predicting the next cards, with the potential to win x32 your initial bet if they correctly predict all 5 cards.
            </Accordion>
            <Accordion title="Can a player stop playing before completing the 5-card series in HI/LO BALLERZ?">
              Yes, a player can stop at any time within the progression of the 5 cards. If they choose to stop, the betting will begin again from scratch, allowing the player to start over with a new initial bet.
            </Accordion>
            <Accordion title="What is the jackpot in HI/LO BALLERZ?">
              The jackpot in HI/LO BALLERZ is an weekly lottery that occurs whenever the pot has filled up or at a certain time. All Players that played and lost any amount on the game will be inside the jackpot; but keep in mind that the bigger % of chance of winning is equal to how many times they played and staked.
            </Accordion>
            <Accordion title="Is HI/LO BALLERZ a game of skill or luck?">
              HI/LO BALLERZ is a combination of skill and luck. While predicting the next card relies on luck, players also need to use their intuition and carefully weigh their options to make calculated predictions. This balance of skill and luck adds to the excitement and challenge of the game.
            </Accordion>
              </AccordionContainer>
            </>
          )}
        </Popup>
      </PopupContainer>
    </>
  );
};


const white = "rgba(254, 254, 254, 1)";

const PopupOverlay = styled.div`
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
`;

const PopupContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;


  const Popup = styled.div`
  background-color: #fff;
  padding: 1rem;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  font-size: 16px;
  font-family: 'Bebas Neue', sans-serif;
  -webkit-text-fill-color: #6e5817;  
  background: transparent; 

  @media (min-width: 768px) {
    padding: 1.5rem;
    max-width: 50%;
  }
`;

const H2 = styled.h2`
  margin: 0;
  color: ${white};
  padding-top: 20px;
  text-align: right;

  @media (min-width: 768px) {
    text-align: right;
  }
`;


const PopUpTitle = styled.h2`
display: absolute;
font-size: 54px; /* Add 'px' unit */

color: #bf9b30;
transition: all 0.2s ease;
`;


const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 0 0 10px black; /* add a black border with a width of 10px */

  background-color: #bf9b30;
  margin-bottom: 1rem;
  background: red;
  padding: 1rem 2rem;
  border-radius: 25px 25px 0 0;  
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
`;


const AccordionContainer = styled.div`
  margin-top: 1rem;
  background: transparent;
  color: gold;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  animation: ${fadeIn} 0.5s ease;
  color: #bf9b30;  
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
`;

const CloseButton = styled.button`
  padding: 0.5rem;
  cursor: pointer;
  background-image: url(${CloseButtonImage});
  background-size: cover;
  background-color: transparent;
  border: none;

`;

export default Faqs;
