import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory, Redirect } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import { Light } from "./styles/Themes";
import GlobalStyles from "./styles/GlobalStyles";
import LogoutTimer from './components/LogoutTimer';
import { disconnectWallet } from './hashConnect';

import Navbar from "./components/Navbar";
import LandingPage from "./components/LandingPage";
import SuccessPage from "./components/SuccessPage";
import FailurePage from "./components/FailurePage";

function App() {
  const [showClaimNFT, setShowClaimNFT] = useState(true);
  const [landingAccessed, setLandingAccessed] = useState(false);
  
  // Clear local storage on app start
  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleLogout = async () => {
    const accessToken = window.localStorage.getItem("access_token");
    if (accessToken !== null && accessToken !== undefined && accessToken !== "null") {
      try {
        const walletType = window.localStorage.getItem("walletType");
        const result = await disconnectWallet(walletType);
        if (result !== false) {
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleLandingButtonClick = () => {
    setLandingAccessed(true);
  };


  return (
    <Router>
      <GlobalStyles />
      <LogoutTimer timeout={6000000} onLogout={handleLogout} />
      <ThemeProvider theme={Light}>
        <Navbar
          setShowClaimNFT={setShowClaimNFT}
          handleLandingButtonClick={handleLandingButtonClick} // Pass the handler as a prop
        />
      </ThemeProvider>
      <Switch>
        <Route path="/landing" exact>
          {landingAccessed ? (
            <LandingPage
              showClaimNFT={showClaimNFT}
              setShowClaimNFT={setShowClaimNFT}
              landingAccessed={landingAccessed}
              setLandingAccessed={setLandingAccessed}
            />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route path="/success" exact>
        
            <SuccessPage />
        </Route>
        <Route path="/failure" exact>
            <FailurePage/>
        </Route>
        {/* Add your home page component here */}
        <Route path="/">
          {/* This route will be accessible by the "Free NFT" button */}
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
