import React, { useState,useContext,useEffect } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import axios from "axios";
import LeaderboardPopup from "./sections/Leaderboard";
import Faqs from "./sections/Faqs";
import Jackpot from "./sections/Jackpot";
import Bank from "./Bank";
import Home from "./sections/Home";
import { connectWalletHashPack, disconnectWallet, connectWalletBlade,initWalletDataHashPack } from '../hashConnect';
import { BalanceContext } from '../BalanceProvider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LeaderboardPage from "./sections/Leaderboard";
import CloseButtonImage from '../assets/close.webp';
import ConfettiComponent from "./Confetti";
import { useHistory } from 'react-router-dom';


import {
  PopupContainer,
  PopupContainerLoss,
  PopupTitle,
  PopupHeader,
  PopupContent,
  PopupTitleJackpot,
  PopupContainerJackpot,
  PopupContainerJackpotPopup,
  HBARLogoImage,
  } from '../Gamecss/index.js';



const Navbar = ({ setShowClaimNFT,  handleLandingButtonClick  }) => {
  const [showHome, setShowHome] = useState(true);
  const [click, setClick] = useState(false);
  const { balance, setBalance } = useContext(BalanceContext);
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [isBankOpen, setIsBankOpen] = useState(false);
  const [isFaqsOpen, setisFaqsOpen] = useState(false);
  const [isJackpotOpen, setisJackpotOpen] = useState(false);
  const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [showPopup, setShowPopup] = useState(false);
  const [showWonJackpot, setshowWonJackpot] = useState(false);
  const [wonJackpotMessage, setwonJackpotMessage] = useState("");
  const [wonJackpotAmount, setwonJackpotAmount] = useState(0);
  const [showDisconnectPopup, setShowDisconnectPopup] = useState(false);
  const [walletType2, setwalletType2] = useState("");
  const [pairingString, setPairingString] = useState("");
  let timeoutId;


  const history = useHistory();

const checkLastJackpotWinner = async () => {
  try {
    const walletObject = {
      accountId: window.localStorage.getItem("wallet"),
    };

    const response = await axios.post(
      process.env.REACT_APP_API_URL + "api/checkLastJackpotWinner",
      walletObject,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    if (response.data.won === true) {
      setBalance(parseFloat(response.data.balance).toFixed(2));
      setwonJackpotAmount(response.data.amountWon);
      var message = "";
      if(response.data.numberOfJackpots === 1){
        message = " JACKPOT!";
      }
      else{
        message = " JACKPOT'S!";
      }
      setwonJackpotMessage("Congratulations! You have won " +response.data.numberOfJackpots +message);
      setshowWonJackpot(true);
    }
    else{
      setwonJackpotAmount(0);
      setwonJackpotMessage("");
      setshowWonJackpot(false);
    }

  } catch (error) {
    if (error.response && error.response.status === 403) {
      setShowDisconnectPopup(true);
      setShowPopup(false);
      setWalletConnected(false);
      setWalletAddress("");
      setBalance(0);
      // Show success notification for wallet disconnection
      setSnackbarMessage('Wallet already connected');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
      return;
    } else {
      console.log(error);
    }
  }
};


const handleDisconnect = () => {
  setShowDisconnectPopup(false);
};


const scheduleCheckLastJackpotWinner = () => {
  const now = new Date();
  const nextMonday = new Date(now);

  // Calculate the next Monday
  nextMonday.setDate(now.getDate() + ((1 + 7 - now.getDay()) % 7));

  // Set the time to midnight PST
  nextMonday.setUTCHours(7, 0, 0, 0); // UTC time equivalent to Monday midnight PST

  const timeUntilNextMonday = nextMonday.getTime() - now.getTime();

  timeoutId = setTimeout(() => {
    checkLastJackpotWinner();
  }, timeUntilNextMonday);
};


useEffect(() => {
  // Schedule the initial call
  scheduleCheckLastJackpotWinner();

  // Clean up the timer when the component is unmounted
  return () => {
    clearTimeout(timeoutId);
  };
}, []);



const handleUserLogin = () => {
  clearTimeout(timeoutId); // Clear the timer if user logs in before the scheduled time
  checkLastJackpotWinner(); // Call the request after a delay of 1 second upon user login
};

  const handleWalletConnect = async (walletType) => {
    try {
      let result;
      let resulthashpack;
      if (walletType === "hashpackwallet") {
        resulthashpack = await connectWalletHashPack();
        setwalletType2("hashpackwallet"); // Set the walletType state
        localStorage.setItem('walletType', 'hashpackwallet');
        result=resulthashpack.result;   
      } else if (walletType === "bladewallet") {
        result = await connectWalletBlade();
        setwalletType2("bladewallet"); // Set the walletType state
        localStorage.setItem('walletType', 'bladewallet');
      }
      if(result === "User already Connected"){
        if (result !== false) {
          setShowDisconnectPopup(true);
          setShowPopup(false);
          setWalletConnected(false);
          setWalletAddress("");
          setBalance(0);
          // Show success notification for wallet disconnection
          setSnackbarMessage('Wallet already connected');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          return;
        }
      }
      const walletObject = {
        accountId: result,
      };

      axios
        .post(process.env.REACT_APP_API_URL + "api/check_balance", walletObject, {
          headers: { Authorization: `Bearer ${localStorage.getItem("access_token")}` },
        })
        .then((response) => {
          setBalance(parseFloat(response.data.balance).toFixed(2));
        })
        .catch((error) => {
          console.log(error);
        });
  

      if (result !== false) {
        setWalletConnected(true);
        setWalletAddress(result);
        handleUserLogin();
        // Show success notification for wallet connection
        setSnackbarMessage("Wallet connected successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }
    } catch (error) {
      // Handle any errors that occur during the Promise resolution
      console.error(error);
  
      // Show error notification for wallet connection
      setSnackbarMessage(`Wallet connection failed: ${error.message}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  
    // Close the popup
    setShowPopup(false);
  };
  
  const handleCloseJackpotWin = () => {
    setshowWonJackpot(false);
  };
  
  const handleDisconnectWallet = async () => {
    try {
      const walletType = window.localStorage.getItem("walletType");
      const result = await disconnectWallet(walletType);
      if (result !== false) {
        setWalletConnected(false);
        setWalletAddress("");
        setBalance(0);
        // Show success notification for wallet disconnection
        setSnackbarMessage('Wallet disconnected successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        window.location.reload();
      }
    } catch (error) {
      // Handle any errors that occur during the Promise resolution
      console.error(error);
      // Show error notification for wallet disconnection
      setSnackbarMessage(`Wallet disconnection failed: ${error.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      window.location.reload();

    }
  };

  const handleLogoClick = () => {
    setShowHome(true);
    handleCloseLeaderboard();
    setisJackpotOpen(false);
    setShowHome(true); 
    history.push('/');
    
  };

  const handleConnectWalletClick = () => {
    // Show the popup
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    // Close the popup
    setisJackpotOpen(false);
    setShowPopup(false);
  };

  const handleBankClick = () => {
    setIsBankOpen(true);
    setisJackpotOpen(false);
    setIsLeaderboardOpen(false);
    setShowHome(true); 
    setShowClaimNFT(false); // Hide LandingPage content
    history.push('/');
  };

  const handleCloseBank = () => {
    setIsBankOpen(false);
  };

  const handleFaqsClick = () => {
    setisFaqsOpen(true);
    setisJackpotOpen(false);
    setIsLeaderboardOpen(false);
    setShowHome(true); 
    setShowClaimNFT(false); // Hide LandingPage content
    history.push('/');
  };

  const handleCloseFaqs = () => {
    setisFaqsOpen(false);
  };

  const handleJackpotClick = () => {
    setisJackpotOpen(true);
    setisFaqsOpen(false);
    setShowHome(false); 
    setIsLeaderboardOpen(false);
    setShowClaimNFT(false); // Hide LandingPage content
    history.push('/');
  };


  const ClaimNFT = () => {
    handleLandingButtonClick(); 

    setisJackpotOpen(false);
    setisFaqsOpen(false);
    setShowHome(false); 
    setIsLeaderboardOpen(false);
    
    history.push('/landing');
  };

  const handleCloseJackpot = () => {
    setisJackpotOpen(false);
    setShowHome(true); 
    setIsLeaderboardOpen(false);
    setisFaqsOpen(false);
  };

  const handleOpenLeaderboard = () => {
    setIsLeaderboardOpen(true);
    setShowHome(false); 
    setisJackpotOpen(false);
    setShowClaimNFT(false); // Hide LandingPage content
    history.push('/');
  };
  
  const handleCloseLeaderboard = () => {
    setIsLeaderboardOpen(false);
    setShowHome(true);
  };
  
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  
  const handleClick = () => setClick(!click);

  return (
    <>
      <Section id="navbar">
        <Nav>
                  {!walletConnected && (
                    <Button onClick={ClaimNFT}>Free NFT | Blade Wallet</Button>
                  )}
          <HamburgerMenu click={click} onClick={handleClick} />
          <Menu click={click}>
            {walletConnected && (
              <>
                <ListItem component={LeaderboardPage} to="/leaderboard" onClick={handleOpenLeaderboard}>
                  Top Ballerz
                </ListItem>
                <ListItem onClick={handleFaqsClick}>Faqs</ListItem>
                <ListItem onClick={handleJackpotClick}>Jackpot</ListItem>
              </>
            )}
            {walletConnected ? (
              <ListItem>
                <div className="mobile">
                  <Button style={{ marginRight: "1.5em" }}>Balance: {balance} h</Button>
                  <Button style={{ marginRight: "1.5em" }} onClick={handleBankClick}>
                    Bank
                  </Button>

                  <Button onClick={handleDisconnectWallet}>{walletAddress}</Button>
                </div>
              </ListItem>
            ) : (
              <ListItem>
                <div className="mobile">
                  <Button onClick={handleConnectWalletClick}>Connect Wallet</Button>
                </div>
              </ListItem>
            )}
          </Menu>

          <Logo onClick={handleLogoClick} />

          {walletConnected ? (
            <div className="desktop">
              <Button style={{ marginRight: "1.5em" }}>Balance: {balance} h</Button>
              <Button style={{ marginRight: "1.5em" }} onClick={handleBankClick}>
                Bank
              </Button>
              <Button onClick={handleDisconnectWallet}>{walletAddress}</Button>
            </div>
          ) : (
            <div className="desktop">
              <Button onClick={handleConnectWalletClick}>Connect Wallet</Button>
            </div>
          )}
        </Nav>
      </Section>

      {showDisconnectPopup && (
                      <PopupContainer>
                        <PopupContainerLoss>
                          <PopupHeader>
                            <PopupTitle>You have been disconnected</PopupTitle>
                            <CloseButton onClick={handleDisconnect } ></CloseButton>
                          </PopupHeader>
                          <PopupContent>
                            <p>You tried logging in on two different browsers so you have been disconnected from both.</p>
                          </PopupContent>
                        </PopupContainerLoss>
                      </PopupContainer>
                    )}

      {showWonJackpot && (
            <PopupContainerJackpotPopup>
             <ConfettiComponent />
              <PopupContainerJackpot>
                <PopupHeader>
                  <PopupTitleJackpot>{wonJackpotMessage}</PopupTitleJackpot>
                  <CloseButton onClick={ handleCloseJackpotWin }></CloseButton><br/><br/>
                </PopupHeader>
                <PopupContent><br/>
                  <PopupTitleJackpot>In total you won</PopupTitleJackpot><br/>
                  <PopupTitleJackpot>{wonJackpotAmount}</PopupTitleJackpot> <HBARLogoImage/><br/><br/>
                  <PopupTitleJackpot>Share in your Socials!</PopupTitleJackpot>
                </PopupContent>
              </PopupContainerJackpot>
            </PopupContainerJackpotPopup>
          )}


      {/* Popup */}
      {showPopup && (
        <PopupContainer>
          <PopupWallet>
            <PopupHeaderWallet>
              <PopupTitleWallet>Select a wallet:</PopupTitleWallet>
              <CloseButton onClick={handlePopupClose}></CloseButton>
            </PopupHeaderWallet>
            <PopupContentWallet>
                <>
                  <PopupTitleWallet1 >Pairing String for HashPack: </PopupTitleWallet1>
                  <textarea id="pairingStringElement" readOnly />
                </>

                
              <Button1 onClick={() => handleWalletConnect("hashpackwallet")} id="qrCodeContainer">HashPack | QR Code</Button1><br/>
                

              <Button onClick={() => handleWalletConnect("hashpackwallet")}>HashPack Wallet</Button><br/>
              <Button onClick={() => handleWalletConnect("bladewallet")}>Blade Wallet</Button>
            </PopupContentWallet>
          </PopupWallet>
        </PopupContainer>
      )}

      {isLeaderboardOpen && <LeaderboardPopup onClose={handleCloseLeaderboard} />}
      {isBankOpen && <Bank show={isBankOpen} onClose={handleCloseBank} />}
      {isFaqsOpen && <Faqs show={isFaqsOpen} onClose={handleCloseFaqs} />}
      {isJackpotOpen && <Jackpot show={isJackpotOpen} onClose={handleCloseJackpot} />}

      <MenuPadding />

      
      {!["/success", "/failure"].includes(window.location.pathname) && showHome && (
        <Home walletConnected={walletConnected} />
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "450%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
  }  

const HamburgerMenu = styled.div`
  width: ${(props) => (props.click ? "1.2rem" : "1.0rem")};
  height: 3px;
  background-color: ${(props) => props.theme.text};
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: ${(props) =>
    props.click
      ? `translateX(-50%) rotate(45deg)`
      : `translateX(-50%) rotate(0)`};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  display: none;

  &:before,
  &:after {
    content: "";
    width: ${(props) => (props.click ? "1.8rem" : "1.5rem")};
    height: 3px;
    background-color: #bf9b30;
    position: absolute;
    transition: all 0.3s ease;
  }

  &:before {
    top: ${(props) => (props.click ? "0" : "-0.7rem")};
    left: 0;
    transform: ${(props) =>
      props.click ? `rotate(-45deg) translate(-0.3rem, 0)` : `none`};
  }

  &:after {
    bottom: ${(props) => (props.click ? "0" : "-0.7rem")};
    left: 0;
    transform: ${(props) =>
      props.click ? `rotate(45deg) translate(-0.3rem, 0)` : `none`};
  }

  @media (max-width: 64em) {
    display: flex;
    top: 1rem; // Adjust the top position as desired
    left: auto; // Reset the left position
    right: 1rem; // Add a right position value
    transform: ${(props) =>
      props.click
        ? `translateX(0%) rotate(45deg)`
        : `translateX(0%) rotate(0)`}; // Modify the transform property

    // Add additional styles for mobile here
    background-color: #ff6f00; // Example: Change the background color
  }
`;


const Section = styled.section`
  width: 100vw;
  background-color: black;
`;

const MenuPadding = styled.div`
  height: 10px; /* Adjust the height to create the desired spacing */
`;


const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  background-text: white;
  align-items: center;
  width: 85%;
  height: ${(props) => props.theme.navHeight};
  margin: 0 auto;
  border-bottom: 2px solid red; /* Add a bottom border */

  .mobile {
    display: none;
  }

  @media (max-width: 64em) {
    background-text: #bf9b30;
    .desktop {
      display: none;
    }

    .mobile {
      display: inline-block;
      background-text: black;
    }
  }
`;


const PopupWallet = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #240808;
  border-radius: 8px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  box-shadow: inset 0 0 5px 5px #bf9b30; /* add a black border with a width of 10px */
  max-height: 80%;
  overflow: auto;
`;

const PopupHeaderWallet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const PopupTitleWallet = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;

  
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
  color: gold ;
  text-align: left;
`;

const PopupTitleWallet1 = styled.h2`
font-size: 0.9rem;
font-weight: 300;


font-family: 'Bebas Neue', sans-serif;
-webkit-text-fill-color: #bf9b30;
color: gold ;
text-align: left;
`;



const PopupContentWallet = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Bebas Neue', sans-serif;
`;

const ListItem = styled.li`
  margin: 0 1.5rem;
  color: #bf9b30;
  cursor: pointer;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    background: red;
    color: black;
    transition: width 0.3s ease;
    position: absolute; /* Add position absolute to move semi-circle to top of parent container */
    left: 50%; /* Move semi-circle to the center of parent container */
    bottom: -7px; /* Move semi-circle below the bottom border */
    transform: translateX(-50%); /* Adjust the position of the semi-circle */
    border-radius: 50%; /* Add border radius to create a semi-circle effect */
    overflow: hidden; /* Hide the overflow of semi-circle */
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
  
  @media (max-width: 64em) {
    margin: 1rem 0;
    color: red;
  }
`;


const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  @media (max-width: 64em) {
    position: fixed;
    top: ${(props) => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
    z-index: 50;
    background-color: ${(props) => `rgba(${props.theme.bodyRgba}, 0.9)`};
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(2px);
    transform: ${(props) =>
      props.click ? `translateY(0)` : `translateY(100%)`};
    transition: all 0.3s ease;
    touch-action: none;
    padding-top: 2rem;
  }
`;

const Button = styled.button`
  padding: 0.5rem;
  color: transparent;
  border: 1px solid #bf9b30;
  border-radius: 5%;
  font-size: ${(props) => props.theme.fontxl};
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
  background: black;

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #7e57c2, 0 0 0 4px white;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
`;

const Button1 = styled.button`
padding: 0.2rem;
color: transparent;
border: 1px solid #bf9b30;
font-size: ${(props) => props.theme.fontxl};
cursor: pointer;
font-size: 1rem;
font-family: 'Bebas Neue', sans-serif;
font-weight: bold;
text-transform: uppercase;
text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
-webkit-text-fill-color: #000000;
background: black;


@media (max-width: 768px) {
  font-size: 0.8rem;
  padding: 0.4rem 0.8rem;
}
`;

const CloseButton = styled.button`
  padding: 0.5rem;
  cursor: pointer;
  background-image: url(${CloseButtonImage});
  background-size: cover;
  background-color: transparent;
  border: none;

`;






export default Navbar;
