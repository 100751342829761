import React, { useState } from 'react';
import styled from "styled-components";
import Game from './Games/game';
import Background2 from '../../assets/frontpage.webp';

const Home = ({ walletConnected }) => {
  const [showGame, setShowGame] = useState(false);

  const handlePlayClick = () => {
    if (walletConnected) {
      setShowGame(true);
    } else {
      alert("Please connect your wallet to play the game.");
    }
  };

  const handleGameClose = () => {
    setShowGame(false);
  };

  return (
    <>
      {showGame ? (
        <Container>
          <Game show={showGame} onClose={handleGameClose} />
        </Container>
      ) : (
        <Section>
          <Subtitle>
            An exciting and challenging game that rewards players for their skill and
            intuition. 
          </Subtitle>
          <Subtitle>With a potential jackpot on the line, players must carefully
            weigh their options and make calculated predictions to come out on top.
            
          </Subtitle>
          <Subtitle>
            Are you ready to take on the challenge and become a HI/LO BALLER?
          </Subtitle>
          {walletConnected && (
            <ButtonContainer>
              <PlayNowButton onClick={handlePlayClick}>Play Now</PlayNowButton>
            </ButtonContainer>
          )}
        </Section>
      )}
      <BackgroundImage playing={showGame} />
    </>
  );
};

const PlayNowButton = styled.button`
  padding: 2rem;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  border: 5px solid #bf9b30;
  border-radius: 9%;
  font-size: ${(props) => props.theme.fontxl};
  cursor: pointer;
  font-size: 34px;

  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
  background: black;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    padding: 0; /* Remove padding on mobile */
    font-size: 24px; /* Adjust font size for mobile */
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  align-self: center;
  text-align: center;
  margin-top: 182px;

  @media (min-width: 48em) {
    align-self: center;
    margin-bottom: 50px;
  }
`;

const Subtitle = styled.p`
  align-self: center;
  text-align: center;
  margin-top: 4rem;
  font-size: 16px;

  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
`;

const Section = styled.section`
  min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
  position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-position: center center;
  background-image: url(${Background2});
  background-size: cover;
  box-shadow: inset 0 0 130px 130px black; /* add a black border with a width of 10px */;
`;

const BackgroundImage = styled.div`
  width: 100%;
  min-height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
`;

const Container = styled.div`
  width: 75%;
  min-height: 10vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 85%;
  }

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    flex-direction: column-reverse;
    width: 100%; /* Adjust width for mobile */
    align-items: flex-start; /* Align items to the start on mobile */

    & > *:first-child {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;


export default Home;
