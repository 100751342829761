import React, { useRef, useState, useEffect } from 'react';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import styled, { keyframes } from "styled-components";
import JackPotImage from '../../assets/nfts_moedas.webp';
import CloseButtonImage from '../../assets/close.webp';
import axios from "axios";
import moment from 'moment-timezone';




const Faqs = ({ onClose }) => {
  const JackpotTimerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeDigit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`;

const [timeLeft, setTimeLeft] = useState({
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
});

useEffect(() => {
  const calculateTimeLeft = () => {
    const now = moment.tz('America/Los_Angeles');
    const targetDay = 0; // Sunday
    const targetHour = 23; // 11 PM
    const targetMinute = 59; // 59 minutes

    const nextTargetDate = now.clone();
    nextTargetDate.day(targetDay).hour(targetHour).minute(targetMinute).second(0).millisecond(0);
   
    if (now.isAfter(nextTargetDate)) {
      nextTargetDate.add(7, 'days');
    }
    console.log('nextTargetDate',nextTargetDate);
    console.log('now',now);
    const timeUntilNextTarget = nextTargetDate.diff(now);

    const duration = moment.duration(timeUntilNextTarget);

    // Format the time digits
    const formattedTimeLeft = {
      days: formatTime(duration.days()),
      hours: formatTime(duration.hours()),
      minutes: formatTime(duration.minutes()),
      seconds: formatTime(duration.seconds()),
    };

    setTimeLeft(formattedTimeLeft);
  };

  const formatTime = (time) => {
    return time.toString().padStart(2, '0');
  };

  // Calculate the time left immediately and then update the countdown every second
  calculateTimeLeft();
  const timer = setInterval(calculateTimeLeft, 1000);

  // Clean up the timer when the component unmounts
  return () => {
    clearInterval(timer);
  };
}, []);







  const ref = useRef(null);
  const [JackpotData, setJackpotData] = useState(null);
  const [jackpotTimer, setJackpotTimer] = useState(0);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {

    axios.post(process.env.REACT_APP_API_URL +'api/getJackpotValue', { accountId: window.localStorage.getItem("wallet"), }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    })
    .then(response => {
    const mockData = {
      endDate: response.data.endDate,
      total: response.data.jackpotValue?.$numberDecimal,
      playerPercentage: response.data.jackpotWinningPercentage?.$numberDecimal,
      lastWinnerJackpot: response.data.lastJackpotWinner?.$numberDecimal,
      lastWinnerPercentage: response.data.lastJackpotWinnerPercentage?.$numberDecimal
    };
      setJackpotData(mockData);
    })
    .catch(error => {
      if (error.response && error.response.status === 403) {
        window.location.reload();
        return;
      } else {
        console.log(error);
      }
    });


  }, []);

  useEffect(() => {
    if (JackpotData && JackpotData.endDate) {
      const currentDate = new Date();
      const targetDate = new Date(JackpotData.endDate);
      const timeDifference = targetDate - currentDate;
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      setJackpotTimer({ days, hours, minutes, seconds });
    }
  }, [JackpotData]);
  
// Default values for days, hours, minutes, and seconds
let days = 0;
let hours = 0;
let minutes = 0;
let seconds = 0;

// Check if jackpotTimer is defined and assign the values
if (jackpotTimer) {
  days = jackpotTimer.days;
  hours = jackpotTimer.hours;
  minutes = jackpotTimer.minutes;
  seconds = jackpotTimer.seconds;
}



return (
  <>
    <PopupContainer ref={ref} id="Jackpot">
      <Popup>
        {!JackpotData ? (
          <Loading>Loading Jackpot...</Loading>
        ) : (
          <>
          <PopupHeader>
            <CloseButton onClick={onClose}></CloseButton>
          </PopupHeader>
            <JackpotContent>
            <div>
              <JackpotContent2>This Week's Jackpot: {parseFloat(JackpotData.total || 0).toFixed(2)} Hbar</JackpotContent2><br/>
              <p>You have {parseFloat(JackpotData.playerPercentage || 0).toFixed(2)}% chance to win this week's jackpot.</p>
            </div><br/>
            <div>
              <JackpotContent2>Last Week's Prize Jackpot: {parseFloat(JackpotData.lastWinnerJackpot || 0).toFixed(2)} Hbar</JackpotContent2><br/>
              <p>Last Winner Had {parseFloat(JackpotData.lastWinnerPercentage || 0).toFixed(2)}% chance to win.</p><br/>
            </div>
            <div><br/>
              <JackpotContent2>How to Enter the Jackpot:</JackpotContent2><br/>
              <p>The jackpot winner is chosen randomly by Sunday Midnight. The more you play, the higher your chance to win the jackpot is!</p>
            </div>
            </JackpotContent>
            <div>
              <JackpotTimerHeadline>Jackpot Timer:</JackpotTimerHeadline>
              <JackpotTimerContainer>
              <TimeDigit>
                <span>{timeLeft.days}</span>
                <span>Days</span>
              </TimeDigit>
              <TimeDigit>
                <span>{timeLeft.hours}</span>
                <span>Hours</span>
              </TimeDigit>
              <TimeDigit>
                <span>{timeLeft.minutes}</span>
                <span>Minutes</span>
              </TimeDigit>
              <TimeDigit>
                <span>{timeLeft.seconds}</span>
                <span>Seconds</span>
              </TimeDigit>
            </JackpotTimerContainer>
            </div>
          </>
        )}
      </Popup>
    </PopupContainer>
  </>
);

  
};  
const PopupContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 110px;
  background-image: url(${JackPotImage});
  box-shadow: inset 0 0 42px 42px black;
  background-size: cover;
  position: fixed;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 1;

  @media (max-width: 768px) {
    /* Adjust these styles for smaller screens */
    margin-top: 60px;
    /* Additional styles or adjustments for smaller screens */
  }

  /* Add more media queries for other screen sizes if needed */
`;

const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 90%;
  max-width: 500px;
  box-shadow: inset 0 0 5px 5px #bf9b30;
  max-height: 80%;
  overflow: auto;
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const PopupTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
  color: white;
`;

const JackpotTimerHeadline = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Bebas Neue', sans-serif;
  color: #333;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 2.5rem;
  }
`;



const JackpotContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Bebas Neue', sans-serif;
`;

const JackpotContent2 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
  color: #000;
  color: gold;
`;


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  animation: ${fadeIn} 0.5s ease;
  color: #f5f5f5;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
`;

const CloseButton = styled.button`
  padding: 0.5rem;
  cursor: pointer;
  background-image: url(${CloseButtonImage});
  background-size: cover;
  background-color: transparent;
  border: none;
`;

export default Faqs;
