import React, { useEffect } from 'react';
import styled from "styled-components";
import btnImageData from '../assets/blade-logo.svg'; // Adjust the path as needed
import Claim from '../assets/claim.webp'; // Adjust the path as needed

const LandingPage = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = '../scripts/index957266ac41801507b2ea.js'; // Replace with the actual URL of the script
    script.async = true;
    script.defer = true;

    // Append the script to the bladeNFTAirdrop element only on the landing page
    const bladeNFTAirdrop = document.getElementById('bladeNFTAirdrop');
    if (window.location.pathname === '/landing' && bladeNFTAirdrop) {
      bladeNFTAirdrop.appendChild(script);
    }

    // Clean up the script when the component unmounts or when leaving the landing page
    return () => {
      if (bladeNFTAirdrop.contains(script)) {
        bladeNFTAirdrop.removeChild(script);
      }
    };
  }, []);

  return (
    <LandingPageWrapper>
      {/* Your landing page content */}
      <MobileContent>
      <div
        id="bladeNFTAirdrop"
        data-use-testnet="false"
        data-dapp-code="RClbrz"
        data-dapp-nonce="cmWOXQ6z0Z7F8PZ3"
        >
        {/* Content related to the script */}
      </div></MobileContent>
    </LandingPageWrapper>
  );
};


const mobileBreakpoint = '768px';

 const LandingPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url(${Claim});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: ${mobileBreakpoint}) {
    background-size: contain; /* Adjust background size for mobile */
  }
  `;
  
  const MobileContent = styled.div`
  /* Additional mobile styling for the content inside the wrapper */
  text-align: center; /* Center align the content */
`;

export default LandingPage;
