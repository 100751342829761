import React, { useState, useEffect } from 'react';

const LogoutTimer = ({ timeout, onLogout }) => {
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    let idleTimer;
    let timeoutTimer;

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      clearTimeout(timeoutTimer);

      // Set the idle timer to log out the user after the specified timeout
      idleTimer = setTimeout(logout, timeout);
    };

    const resetTimeoutTimer = () => {
      clearTimeout(timeoutTimer);

      // Set the timeout timer to log out the user after a certain period of inactivity
      timeoutTimer = setTimeout(logout, timeout);
    };

    const clearTimers = () => {
      clearTimeout(idleTimer);
      clearTimeout(timeoutTimer);
    };

    const logout = () => {
      // Clear the timers and log out the user
      clearTimers();
      onLogout();
    };

    const handleActivity = () => {
      // Reset the idle timer and timeout timer on user activity
      resetIdleTimer();
      resetTimeoutTimer();
    };

    const handleBeforeUnload = () => {
      // Call the logout function before the user leaves the page
      logout();
    };

    // Attach event listeners for user activity and beforeunload
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('mousedown', handleActivity);
    document.addEventListener('touchstart', handleActivity);
    document.addEventListener('visibilitychange', handleActivity);
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Initialize the timers on component mount
    resetIdleTimer();
    resetTimeoutTimer();

    // Clean up the timers and event listeners on component unmount
    return () => {
      clearTimers();
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('mousedown', handleActivity);
      document.removeEventListener('touchstart', handleActivity);
      document.removeEventListener('visibilitychange', handleActivity);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [timeout, onLogout]);

  return null;
};

export default LogoutTimer;
