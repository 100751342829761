import React, { useState } from 'react';
const BalanceContext = React.createContext();

const BalanceProvider = ({ children }) => {
  const [balance, setBalance] = useState(0);

  return (
    <BalanceContext.Provider value={{ balance, setBalance }}>
      {children}
    </BalanceContext.Provider>
  );
};

export { BalanceContext, BalanceProvider };