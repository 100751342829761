import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { BalanceProvider } from "./BalanceProvider/index";

ReactDOM.render(
  <React.StrictMode>
    <BalanceProvider>
      <BrowserRouter>
        <App/>
        
      </BrowserRouter>
    </BalanceProvider>
  </React.StrictMode>,
  document.getElementById("root")
);