import React, { useState, useContext,useEffect} from 'react';
import axios from "axios";
import { BalanceContext } from '../../../BalanceProvider';
import CardBack from '../../../assets/RCgamecard.webp';
import two_of_clubs from '../../../assets/cards/2_of_clubs.png';
import three_of_clubs from '../../../assets/cards/3_of_clubs.png';
import four_of_clubs from '../../../assets/cards/4_of_clubs.png';
import five_of_clubs from '../../../assets/cards/5_of_clubs.png';
import six_of_clubs from '../../../assets/cards/6_of_clubs.png';
import seven_of_clubs from '../../../assets/cards/7_of_clubs.png';
import eight_of_clubs from '../../../assets/cards/8_of_clubs.png';
import nine_of_clubs from '../../../assets/cards/9_of_clubs.png';
import ten_of_clubs from '../../../assets/cards/10_of_clubs.png';
import jack_of_clubs from '../../../assets/cards/jack_of_clubs.png';
import queen_of_clubs from '../../../assets/cards/queen_of_clubs.png';
import king_of_clubs from '../../../assets/cards/king_of_clubs.png';
import ace_of_clubs from '../../../assets/cards/ace_of_clubs.png';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';



import {
  PopupContainer,
  PopupContainerLoss,
  Popup,
  PopupHeader,
  PopupTitle,
  CloseButton,
  PopupContent,
  InstructionsContainer,
  CashOutContainer,
  HigherLowerContainer,
  PlayerCardContainer,
  PlayerCardContainer1,
  Header,
  BetInputContainer,
  InstructionsButton,
  Button,
  GameContainer,
  ButtonJackpot,
  BetLabel,
  BetLabel1,
  CardWrapper,
  CardContainer,
  GameOverContainer,
  GameBackgroundImage,
  ButtonConfirm,
  Won,
  Lost,
  AudioControls,
  PlaybackWidget,
  TransparentButton,
  AudioPlayer,
  GameControlsContainer,
  } from '../../../Gamecss/index.js';


  const songData = [
    {
      title: "Do It Better (feat. Zoe Wees) - Felix Jaehn",
      filename: "Do It Better (feat. Zoe Wees) - Felix Jaehn.mp3"
    },
    {
      title: "Faith - MOGUAI",
      filename: "Faith - MOGUAI.mp3"
    },
    {
      title: "Hasta La Vista - Extended Mix - Luciana",
      filename: "Hasta La Vista - Extended Mix - Luciana.mp3"
    },
    {
      title: "Hot In It - Tiësto",
      filename: "Hot In It - Tiësto.mp3"
    },
    {
      title: "Just In Case - Timmy Trumpet",
      filename: "Just In Case - Timmy Trumpet.mp3"
    }
  ];

const Game = () => {
  const [bet, setBet] = useState(10);
  const [currentCard, setCurrentCard] = useState(null);
  const [nextCard, setNextCard] = useState(null);
  const [winnings, setWinnings] = useState(0);
  const [cardRecord, setCardRecord] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [jackpot, setJackpot] = useState(2000);
  const [progress, setProgress] = useState(0);
  const [showInstructions, setShowInstructions] = useState(true);
  const [gameStarted, setGameStarted] = useState(false);
  const [cashOutEnabled, setCashOutEnabled] = useState(false);
  const [roundsPlayed, setRoundsPlayed] = useState(0);
  const [houseCardImage, setHouseCardImage] = useState(null);
  const [cashOutAmount, setCashOutAmount] = useState(0);
  const [betId, setBetId] = useState(null);
  const [clientCardValue, setclientCardValue] = useState(null);
  const { balance, setBalance } = useContext(BalanceContext);
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [displayCashOut, setdisplayCashOut] = useState(false);
  const [showWinPopup, setShowWinPopup] = useState(false);
  const [showLossPopup, setShowLossPopup] = useState(false);
  const [flippedCards, setFlippedCards] = useState([]);
  const [clickedCards, setClickedCards] = useState(Array(5).fill(false))
  const [rounds, setRounds] = useState([[], [], [], [], []]);
  const [displayHigherLower, setDisplayHigherLower] = useState(true);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [lastRoundPlayed, setLastRoundPlayed] = useState(0);
  const [options, setOptions] = useState( ['higher', 'lower']);
  const [jackpotValue, setjackpotValue] = useState(0);
  const isDesktop = window.innerWidth >= 768;
  const isTablet = window.innerWidth >= 480 && window.innerWidth < 768; // Adjust tablet breakpoint
  


  //Notifications
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState(null);
  
  const [showDisconnectPopup, setShowDisconnectPopup] = useState(false); //Define setShowDisconnectPopup to call
  const [showLoginPopup, setShowLoginPopup] = useState(false); //Define setShowLoginPopup to call

  const cardImages = {
    'CardBack':CardBack,
    '2_of_clubs.png': two_of_clubs,
    '3_of_clubs.png': three_of_clubs,
    '4_of_clubs.png': four_of_clubs,
    '5_of_clubs.png': five_of_clubs,
    '6_of_clubs.png': six_of_clubs,
    '7_of_clubs.png': seven_of_clubs,
    '8_of_clubs.png': eight_of_clubs,
    '9_of_clubs.png': nine_of_clubs,
    '10_of_clubs.png': ten_of_clubs,
    'jack_of_clubs.png': jack_of_clubs,
    'queen_of_clubs.png': queen_of_clubs,
    'king_of_clubs.png': king_of_clubs,
    'ace_of_clubs.png': ace_of_clubs
  };


  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  const handleNextSong = () => {
    setCurrentSongIndex((currentSongIndex + 1) % songData.length);
  };

  const handlePreviousSong = () => {
    setCurrentSongIndex(
      (currentSongIndex - 1 + songData.length) % songData.length
    );
  };

  const currentSong = songData[currentSongIndex];


  useEffect(() => {
    // This function is called whenever currentSong changes
    const audioPlayer = document.getElementById('audio-player');
    audioPlayer.load(); // Reload the audio element to play the new song
  }, [currentSong]);

  const handlePlaceBetConfirm = () => {
    const checkOpenBetObject = {
      accountId: window.localStorage.getItem("wallet"),
    };
  
    axios.post(process.env.REACT_APP_API_URL + 'api/checkOpenBet', checkOpenBetObject, {
      headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
    })
      .then((response) => {
        if (response.data.message === "Bet not found.") {
          const betObject = {
            accountId: window.localStorage.getItem("wallet"),
            amountBetted: bet
          };
    
          axios.post(process.env.REACT_APP_API_URL + 'api/startBet', betObject, {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
          })
            .then((response) => {
              if (response.data.message === "New bet saved successfully!") {
                const balance = parseFloat(response.data.balance.$numberDecimal);
    
                if (isNaN(balance)) {
                  // Show no balance notification
                  setSnackbarMessage("Insufficient balance. Please add funds.");
                  setSnackbarSeverity("warning");
                  setSnackbarOpen(true);
                } else {
                  setBetId(response.data.betId);
                  setGameStarted(true);
                  setCashOutEnabled(false);
                  setShowConfirmationAlert(false);
                  setBalance(parseFloat(response.data.balance.$numberDecimal).toFixed(2));
                  setNextCard(true);
    
                  // Show success notification
                  setSnackbarMessage("Bet placed successfully");
                  setSnackbarSeverity("success");
                  setSnackbarOpen(true);
                }
              } else {
                // Show error notification for failed bet
                setSnackbarMessage("Insufficient balance. Please add funds.");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 403) {
                setShowDisconnectPopup(true);
                window.location.reload();
                // Show success notification for wallet disconnection
                setSnackbarMessage('Wallet already connected');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                return;
              } else {
                // Show error notification
                setSnackbarMessage("Failed to place bet");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
                console.log(error);
              }

            });
    
        }
    
        else{
          if(response.data.finished === false && response.data.withdrawn === false){
            let round = response.data.round;
            setBetId(response.data.betId);
            setclientCardValue(response.data.lastCardValue);
            setWinnings(response.data.amountWon.$numberDecimal);
            const card = {
              cardValue: response.data.lastCardValue,
              cardImage: response.data.lastCardImage
            };

            if (response.data.cardTurned === true) {
              setdisplayCashOut(true);
              setProgress(Number(round));
              setClickedCards(prevClickedCards => {
                const newClickedCards = prevClickedCards.slice(round); // Remove the previous indexes before the specified round
                newClickedCards[0] = true; // Add `true` to the index corresponding to the updated round value
                return newClickedCards;
              });
              setFlippedCards(prevFlippedCards => {
                const newFlippedCards = prevFlippedCards.slice(round); // Remove the previous indexes before the specified round
                newFlippedCards[0] = 'current'; // Add 'current' to the index corresponding to the updated round value
                return newFlippedCards;
              });
              setRounds(prevRounds => {
                const newRounds = prevRounds.slice(round); // Remove the previous indexes before the specified round
                newRounds[0] = [card]; // Add the card to the index corresponding to the updated round value
                return newRounds;
              });
            }
            else{
              setCashOutEnabled(true);
              setProgress(Number(round));
              setClickedCards(prevClickedCards => {
                const newClickedCards = prevClickedCards.slice(round); // Remove the previous indexes before the specified round
                newClickedCards[0] = false; // Add `true` to the index corresponding to the updated round value
                return newClickedCards;
              });
              setFlippedCards(prevFlippedCards => {
                const newFlippedCards = prevFlippedCards.slice(round); // Remove the previous indexes before the specified round
                newFlippedCards[0] = 'current'; // Add 'current' to the index corresponding to the updated round value
                return newFlippedCards;
              });
              setRounds(prevRounds => {
                const newRounds = prevRounds.slice(round); // Remove the previous indexes before the specified round
                newRounds[0] = []; // Add the card to the index corresponding to the updated round value
                return newRounds;
              });
            }
            


  
            setRequestInProgress(false); 
            setGameStarted(true);
            setShowConfirmationAlert(false);
            setNextCard(true);
            
            if(response.data.lastCardValue === 2){
              setOptions(['higher']);
            }else if(response.data.lastCardValue === 14){
              setOptions(['lower']);
            }else{
              setOptions(['higher', 'lower']);
            };
            setLastRoundPlayed(response.data.round);
            setHouseCardImage("CardBack");
            
            // Show success notification
            setSnackbarMessage("Bet returned successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setShowDisconnectPopup(true);
          window.location.reload();
          // Show success notification for wallet disconnection
          setSnackbarMessage('Wallet already connected');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          return;
        } else {
          console.log(error);
        }
      });


  };
  
    
    const cashOut = () => {
      axios.post(process.env.REACT_APP_API_URL +'api/cashOut', { betId }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
      })
      .then(response => {
        const { balance, message } = response.data;
        
        if (message === "Cashout successful") {
          setGameOver(true);
          setCashOutAmount(winnings);
          setBalance(parseFloat(response.data.balance.$numberDecimal).toFixed(2));
          setLastRoundPlayed(0);

        } 
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          setShowDisconnectPopup(true);
          window.location.reload();
          // Show success notification for wallet disconnection
          setSnackbarMessage('Wallet already connected');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          return;
        } else {
          console.log(error);
        }
      });

  
    };

    const handleCloseSnackbar = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
  
      setSnackbarOpen(false);
    };
    
    
    const handleHigherLower = async (prediction) => {
      // Set the prediction and displayHigherLower states
      setDisplayHigherLower(false);

      var higherOrLower = 0;
      if(prediction !== 'higher'){
        higherOrLower= -1;
      }else{
        higherOrLower= 1;
      }
      try {
        // Send the user card value, bet value, and bet ID to the backend
        
    const predictionObject = {
      betId: betId,
      userCardValue: clientCardValue,
      betValue: higherOrLower
    };

        const response = await axios.post(
          process.env.REACT_APP_API_URL + 'api/randomHouseCard',
          predictionObject,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        );
    
        const { result, houseCard } = response.data;
        setHouseCardImage(houseCard.cardImage);
        // Determine the game result based on the user's card value, house card value, and prediction
        const gameResult = result.result === 'Won' ? 'win' : 'loss';
        if (gameResult === 'win') {

          setRoundsPlayed(roundsPlayed + 1);
          setProgress(progress + 1);
          setCashOutEnabled(true);
          setdisplayCashOut(true);
          setWinnings(result.amountWon);
          setSelectedOption(null); // Reset the selected option
          setCardRecord([
            ...cardRecord,
            {
              playerCard: clientCardValue,
              challengerCard: houseCard.cardValue,
              prediction: gameResult === 'win' ? 'correct' : 'incorrect'
            }
          ]);
        
          setTimeout(() => {
            setShowWinPopup(true);
          }, 1500); // Delay of 3.5 seconds (3500 milliseconds)
        } else {
          setTimeout(() => {
            setShowLossPopup(true);
          }, 1500); // Delay of 3.5 seconds (3500 milliseconds)
        }
        
        // Handle the game result
        //handleResult(gameResult, houseCardValue);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setShowDisconnectPopup(true);
          window.location.reload();
          // Show success notification for wallet disconnection
          setSnackbarMessage('Wallet already connected');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          return;
        } else {
          console.log(error);
        }
      }
    };
    
    
    

    const handleCloseWinPopupLoss = () => {
      setShowLossPopup(false);
      setTimeout(() => {
        setWinnings(0); // reset winnings to 0
        setGameStarted(false);
        setCashOutEnabled(false);
        setSelectedOption(null); // Reset the selected option
        setGameOver(true);
        setLastRoundPlayed(0);
      }, 1200);
    };
    
    //Disconnect Jogador inativo
    const handleDisconnect = () => {
    
      setShowDisconnectPopup(false);
      setShowLoginPopup(false);
    };
    


    const handleCloseWinPopup = () => {
      if(roundsPlayed  === 5){
        cashOut();
        return;
      }
      setShowWinPopup(false);
      setdisplayCashOut(false);
      setDisplayHigherLower(true);
    };
    

    const handleNewGame = () => {
      setBet(10);
      setCurrentCard(null);
      setNextCard(null);
      setWinnings(0);
      setCardRecord([]);
      setGameOver(false);
      setJackpot(2000);
      setProgress(0);
      setShowInstructions(true);
      setGameStarted(false);
      setCashOutEnabled(false);
      setRoundsPlayed(0);
      setHouseCardImage(null);
      setCashOutAmount(0);
      setBetId(null);
      setclientCardValue(null);
      setShowConfirmationAlert(false);
      setdisplayCashOut(false);
      setShowWinPopup(false);
      setShowLossPopup(false);
      setFlippedCards([]);
      setClickedCards(Array(5).fill(false));
      setRounds(Array(5).fill([]));
      setDisplayHigherLower(true);
      setRequestInProgress(false);
      setLastRoundPlayed(0);
    };
    
    



    
        
      
      const betAmounts = [10, 50, 100];


      
      const ConfirmationAlert = ({ onConfirm, onCancel, bet }) => {
        return (
          <div>
            <p>You sure you want to bet {bet}?</p>
            <Button onClick={onConfirm}>Yes</Button>
            <Button onClick={onCancel}>No</Button>
          </div>
        );
      };
      
      
      const getBackgroundColor = (index, progress) => {
        const reversedIndex = progress - index - 1;
        let goldValue, brightnessValue, opacityValue;
      
        if (index === 0) { // if it's the first bet
          goldValue = 10;
          brightnessValue = 90;
          opacityValue = 1;
        } else {
          goldValue = Math.min(10, 11 - progress + reversedIndex);
          brightnessValue = 10 + (reversedIndex * 90);
          opacityValue = 0.2 * (5 - index + 1);
        }
      
        return `hsla(43, ${brightnessValue}%, ${goldValue}0%, ${opacityValue})`;
      };
      
      
      
      const GameInstructions = () => {
        return (
          <InstructionsContainer>
            <div className="instructions">
              <h2>How to play?</h2>
              <ol>
                <li>Place your bet.</li>
                <li>Predict whether the next card will be higher or lower than the current card.</li>
                <li>If you predict correctly, you win double your bet. If you predict incorrectly, you lose your bet.</li>
                <li>The game continues until you have predicted incorrectly, or until you reach the end of the deck.</li>
              </ol>
            </div>
            <div className="card-values">
              <h2>Card Values:</h2>
              <ul>
                <li>Ace (A): 14</li>
                <li>King (K): 13</li>
                <li>Queen (Q): 12</li>
                <li>Jack (J): 11</li>
                <li>Numerical cards: Face value (2-10)</li>
              </ul>
            </div>
          </InstructionsContainer>
        );
      };
      
      useEffect(() => {

        axios.post(process.env.REACT_APP_API_URL +'api/getJackpotValue', { accountId: window.localStorage.getItem("wallet"), }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
        })
        .then(response => {
          setjackpotValue(response.data.jackpotValue?.$numberDecimal);
        })
        .catch(error => {
          if (error.response && error.response.status === 403) {
            setShowDisconnectPopup(true);
          window.location.reload();
          // Show success notification for wallet disconnection
          setSnackbarMessage('Wallet already connected');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          return;
          } else {
            console.log(error);
          }
        });
    
    
      }, []);
    
      
      

      return (
            <GameContainer>
              <Header>
              <ButtonJackpot backgroundColor={getBackgroundColor(progress.length, 5)}>Jackpot Value:<br/>{parseFloat(jackpotValue || 0).toFixed(2)} hbar</ButtonJackpot>
                <ButtonJackpot>Score:<br/>{winnings} Hbar</ButtonJackpot>
              </Header>

              
              <PlaybackWidget>
                <h2 style={{ color: 'gold', fontSize: '18px'}}>{currentSong.title}</h2>
                
                <AudioControls>
                  <TransparentButton onClick={handlePreviousSong}>⏪</TransparentButton>
                  <TransparentButton onClick={handleNextSong}>⏩</TransparentButton>
                </AudioControls>
                <AudioPlayer controls id="audio-player" controlsList="nodownload" >
                  <source
                    src={require(`../../../assets/videos/${currentSong.filename}`)}
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio element.
                </AudioPlayer>
              </PlaybackWidget>


                {!gameOver && gameStarted && (
                  <div style={{ display: 'flex', width: '50%', width: '50%', justifyContent: 'center'  }}>
                    <div style={{ width: '100%', marginBottom: '55px' }}>
                     
                      
              
                      <CardWrapper style={{ width: '100%', justifyContent: 'center' }}>
                        
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                          {nextCard && (
                            <div style={{ width: '130%', cursor: 'pointer' }}>
                              <CardWrapper>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                  <div style={{ width: '100%', cursor: 'pointer' }}>
                                  <BetLabel>House</BetLabel>
                                    <CardContainer
                                      style={{
                                        backgroundImage:
                                          houseCardImage !== null
                                            ? `url(${cardImages[houseCardImage]})`
                                            : `url(${CardBack})`,
                                        transform: nextCard ? 'rotateY(720deg) scale(1)' : 'none',
                                        transition: 'transform 3.5s ease-in-out'
                                      }}
                                    >
                                      <div className="visually-hidden"></div>
                                    </CardContainer>
                                  </div>
                                </div>
                              </CardWrapper>
                            </div>
                          )}
                        </div>
                      </CardWrapper>
                    </div>



                    {showWinPopup && (
                      <PopupContainer>
                        <Popup>
                          <PopupHeader>
                            <PopupTitle>You won round {progress}!</PopupTitle>
                            <CloseButton onClick={handleCloseWinPopup}></CloseButton>
                          </PopupHeader>
                          <PopupContent>
                            <p>Congratulations! You won round {progress}.</p><br></br>
                            <p>Your current winnings are: {winnings}</p>
                            <Won/>
                          </PopupContent>
                        </Popup>
                      </PopupContainer>
                    )}

                    {showLossPopup && (
                      <PopupContainer>
                        <PopupContainerLoss>
                          <PopupHeader>
                            <PopupTitle>You lost round {progress + 1}!</PopupTitle>
                            <CloseButton onClick={handleCloseWinPopupLoss}></CloseButton>
                          </PopupHeader>
                          <PopupContent>
                            <p>Oops! You lost round {progress + 1}.</p><br></br>
                            <p>You could have withdrawn {winnings}</p>
                            <Lost/>
                          </PopupContent>
                        </PopupContainerLoss>
                      </PopupContainer>
                    )}




                    {showDisconnectPopup && (
                      <PopupContainer>
                        <PopupContainerLoss>
                          <PopupHeader>
                            <PopupTitle>You have been disconnected</PopupTitle>
                            <CloseButton onClick={handleDisconnect } ></CloseButton>
                          </PopupHeader>
                          <PopupContent>
                            <p>You have been disconnected because you were inactive for over 10 minutes.</p>
                          </PopupContent>
                        </PopupContainerLoss>
                      </PopupContainer>
                    )}




                    {showLoginPopup && (
                      <PopupContainer>
                        <PopupContainerLoss>
                          <PopupHeader>
                            <PopupTitle>You have been disconnected</PopupTitle>
                            <CloseButton onClick={handleDisconnect } ></CloseButton>
                          </PopupHeader>
                          <PopupContent>
                            <p>You tried logging in on two different browsers so you have been disconnected from both.</p>
                          </PopupContent>
                        </PopupContainerLoss>
                      </PopupContainer>
                    )}



        <GameControlsContainer>
  {nextCard && (
    <>
      <div >
        {/* Content */}
      </div>

      {!displayCashOut && (
        <PlayerCardContainer>
            <ButtonConfirm>Press Player Card</ButtonConfirm>
        </PlayerCardContainer>
      )}

      {displayCashOut && displayHigherLower && (
        <div>
          <PlayerCardContainer1>
            <Button >
              Take your Guess
            </Button>
          </PlayerCardContainer1>

          <HigherLowerContainer>
            {options.map(option => (
              <Button
                key={option}
                onClick={() => {
                  setSelectedOption(option); // Set the selected option in the state
                }}
              >
                {option}
              </Button>
            ))}
          </HigherLowerContainer>

          {selectedOption && (
            <ButtonConfirm
              
              onClick={() => {
                handleHigherLower(selectedOption, bet); // Call handleHigherLower with the selected option and bet
              }}
            >
              Confirm {selectedOption && `(${selectedOption})`}
            </ButtonConfirm>
          )}
        </div>
      )}

      {cashOutEnabled && !displayCashOut && winnings > 0 && (
        <CashOutContainer >
          {cashOutAmount ? (
            <p>You cashed out: {cashOutAmount}</p>
          ) : (
            <p></p>
          )}
          <Button
            
            onClick={() => {
              if (window.confirm('Are you sure you want to cash out?')) {
                cashOut();
              }
            }}
          >
            Cash Out
          </Button>
        </CashOutContainer>
      )}
    </>
  )}
</GameControlsContainer>

              </div>

            )}



{!gameOver && gameStarted && (
  
  <div
    style={{
      width: '88%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: window.innerWidth >= 768 ? '175px' : '0', // Apply marginLeft only on desktop
      marginLeft: window.innerWidth >= 1024 ? '55px' : '0', // Apply marginLeft only on desktop
    }}
  >
<BetLabel1>
  Player
</BetLabel1>
    <div style={{ width: '100%', marginBottom: '10px'}}>
      <CardWrapper style={{ width: '88%', alignItems: 'center'}}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: window.innerWidth >= 768 ? 'row' : 'column', // Change flexDirection based on screen size
            marginLeft: window.innerWidth >= 1024 ? '55px' : '0', // Apply marginLeft only on desktop
          }}
        >
          {rounds.map((_, i) => (
            <div
              key={i}
              style={{
                position: 'relative',
                width: '50%',
                cursor: 'pointer',
                marginleft: '50px',
                flexDirection: window.innerWidth >= 768 ? 'row' : 'column', // Change flexDirection based on screen size
                marginLeft: window.innerWidth >= 1024 ? '55px' : '0', // Apply marginLeft only on desktop
                flexWrap: 'wrap', // Wrap items to the next column when needed
              }}
              onClick={() => {
                var e = i;
                if (lastRoundPlayed !== 0) {
                  e = i + lastRoundPlayed;
                }
    
                          if (e === progress) { // check if current round is being played
                            if (!clickedCards[i]) {
                              setClickedCards(prevClickedCards => prevClickedCards.map((card, index) => (index === i ? true : card)));
                              setRequestInProgress(true); // Set request in progress to true
                              axios.post(process.env.REACT_APP_API_URL +'api/randomClientCard', { betId }, {
                                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
                              })
                              .then(response => {
                                const { card, message, success } = response.data;
                                
                                if (success) {
                                  if(card.cardValue === 2){
                                    setOptions(['higher']);
                                  }else if(card.cardValue === 14){
                                    setOptions(['lower']);
                                  }else{
                                    setOptions(['higher', 'lower']);
                                  };
                                  setHouseCardImage("CardBack");
                                  setFlippedCards(prevFlippedCards => {
                                    const newFlippedCards = [...prevFlippedCards];
                                    newFlippedCards[i] = 'current'; 
                                    return newFlippedCards;
                                  });
                              
                                  setclientCardValue(card.cardValue);
                                  

                                  setdisplayCashOut(true);
                                  setRounds(prevRounds => {
                                    const newRounds = [...prevRounds];
                                    newRounds[i] = [...newRounds[i], card];
                                    return newRounds;
                                  });
                                  setRequestInProgress(false); // Set request in progress to false after processing is complete
                               
                                 

                                } else {
                                  setRequestInProgress(false); // Set request in progress to false if the request failed
                                }
                              })
                              .catch(error => {
                                if (error.response && error.response.status === 403) {
                                  setShowDisconnectPopup(true);
                                  window.location.reload();
                                  // Show success notification for wallet disconnection
                                  setSnackbarMessage('Wallet already connected');
                                  setSnackbarSeverity('success');
                                  setSnackbarOpen(true);
                                  return;
                                } else {
                                  setRequestInProgress(false); // Set request in progress to false if there was an error
                                }

                              });
                            }
                          }
                        }}
                        // Disable card flipping animation while request is in progress
                        className={requestInProgress ? 'disable-clicks' : ''}
                      >
                        <CardContainer
                            style={{
                              backgroundImage:
                                flippedCards[i] === 'current' && clickedCards[i]
                                  ? `url(${cardImages[rounds[i][0]?.cardImage]})`
                                  : `url(${CardBack})`,
                              transform:
                                flippedCards[i] === 'current' && clickedCards[i]
                                  ? 'rotateY(720deg) scale(1)'
                                  : requestInProgress // rotate the card when request is in progress
                                  ? 'rotateY(360deg) scale(0.8)'
                                  : 'none',
                              transition: 'transform 2.5s ease-in-out',
                              margin: '5px 0', // Add margin to separate cards
                            }}
                            >
                              <div className="visually-hidden"></div>
                        
                              <div style={{ position: 'absolute', top: '189px', left: '35px', padding: '0.45rem', fontSize: '0.75rem', fontWeight: 'bold', color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>Round {lastRoundPlayed +i + 1}</div>
                            </CardContainer>
                          </div>
                        ))}
                      </div>
                    </CardWrapper>
                  </div>
                </div>
              )}



          {!gameOver && !gameStarted && (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ marginBottom: '20px' }}>
                <CardContainer className='card-back' style={{ width: '250px', height: '350px', marginTop: '90px' }}>
                  <div className="visually-hidden"></div>
                </CardContainer>
              </div>

              <BetInputContainer>
                {betAmounts.map((amount) => (
                  <Button key={amount} onClick={() => setBet(amount)}>
                    {amount}
                  </Button>
                ))}

                {bet > 0 && (
                  <Button onClick={() => {
                    const confirmBet = window.confirm(`Are you sure you want to bet ${bet}?`);
                    if (confirmBet) {
                      handlePlaceBetConfirm(); // Add this line to reset the displayCashOut state
                    }
                  }}>
                    Place Bet ({bet})
                  </Button>
                )}
              </BetInputContainer>
            </div>
          )}


          {gameOver && (
            <GameOverContainer>
              <h2>Game Over</h2>
              {winnings > 0 ? (
                <p>You won: {winnings}</p>
              ) : (
                <p>You lost your initial bet</p>
              )}
              {jackpot === 0 && (
                <p>The jackpot was won in this game!</p>
              )}
              <Button onClick={() => {        
                handleNewGame();
              }}>Play Again</Button>
            </GameOverContainer>
          )}
          
    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
      <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
    
      {showConfirmationAlert && (
          <ConfirmationAlert
            onConfirm={() => {handlePlaceBetConfirm()}}
            onCancel={() => setShowConfirmationAlert(false)}
            bet={bet}
          />
        )}

          <GameBackgroundImage />
          <div>
            <InstructionsButton onClick={() => setShowInstructions(!showInstructions)}>
              {showInstructions ? 'Hide Instructions' : 'Show Instructions'}
            </InstructionsButton>
          </div>
          {showInstructions && <GameInstructions />}

         
        </GameContainer>
        
      );
      
      
  }




  

export default Game;
