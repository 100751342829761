import React, { useState } from "react";
import styled from "styled-components";
import { Minus } from "../Icons/Minus";
import { Plus } from "../Icons/Plus";

const Accordion = ({ title, children }) => {
  const [collapse, setCollapse] = useState(false);
  const [dontExpandPopup, setDontExpandPopup] = useState(false);

  return (
    <Container className={dontExpandPopup ? "dont-expand-popup" : ""}>
      <Title
        onClick={() => {
          setCollapse(!collapse);
          setDontExpandPopup(true);
        }}
      >
        <Name>
          <IconWrapper>
            {collapse ? <MinusIcon /> : <PlusIcon />}
          </IconWrapper>
          <span>{title}</span>
        </Name>
      </Title>
      <Reveal clicked={collapse}>{children}</Reveal>
    </Container>
  );
};


const Container = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 15px;
  background: black;

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }

  @media only screen and (max-width: 767px) {
    padding: 10px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.text};

  svg {
    margin-right: 10px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

const Reveal = styled.div`
  display: ${(props) => (props.clicked ? "block" : "none")};
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.5;

  &.dont-expand-popup {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    margin-top: 5px;
    font-size: 14px;
  }
`;


const Name = styled.div`
  display: flex;
  align-items: center;
  color: #bf9b30;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

const PlusIcon = styled(Plus)`
  width: 16px;
  height: 16px;
  fill: red;

  @media only screen and (max-width: 767px) {
    width: 14px;
    height: 14px;
  }
`;

const MinusIcon = styled(Minus)`
  width: 16px;
  height: 16px;
  fill: red;

  @media only screen and (max-width: 767px) {
    width: 14px;
    height: 14px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text};

  @media only screen and (max-width: 767px) {
    margin-right: 5px;
  }
`;


export default Accordion;
