// This file contains variables for different thremes

export const Light = {
  body: "#F2F2F2",
  text: "#1E1E1E",
  bodyRgba: "242, 242, 242",
  textRgba: "30, 30, 30",

  carouselColor: "#f9D9D9",

  fontxs: "0.75rem",
  fontsm: "0.875rem",
  fontmd: "1rem", // 1rem = 16px
  fontlg: "1.25rem",
  fontxl: "2rem",
  fontxxl: "3rem",
  fontxxxl: "4rem",

  fontButton: "0.875rem",

  navHeight: "5rem",
};

export const Dark = {
  body: "#1E1E1E",
  text: "#F2F2F2",
  bodyRgba: "30, 30, 30",
  textRgba: "242, 242, 242",

  carouselColor: "#333333",

  fontxs: "0.75rem",
  fontsm: "0.875rem",
  fontmd: "1rem", // 1rem = 16px
  fontlg: "1.25rem",
  fontxl: "2rem",
  fontxxl: "3rem",
  fontxxxl: "4rem",

  fontButton: "0.875rem",

  navHeight: "5rem",
};
