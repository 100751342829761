import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    *, ::before, ::after{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    .card-value {
        color: white;
        margin-bottom: 200px;
        font-size: 0.3em;
        margin-left: 0.2em;
        
      }

      

    .card-suit {
      vertical-align: middle;
      color: white;
      margin-left: 0.2em;
      font-size: 0.3em;
      margin-bottom: 200px;
    }
      
      
    body{
        font-family: 'Bebas Neue', sans-serif;
        overflow-x: hidden ;
        background-color: black;
        color: white;
    }
    
    h1,h2,h3,h4{
        margin: 0;
        padding: 0;
        color: white;
        font-family: 'Montserrat', sans-serif;
    }
    a{
        color: inherit;
        text-decoration: none;
        font-family: 'Roboto', sans-serif;
    }

    ul{
        list-style: none;
    }
`;

export default GlobalStyles;
