import React from "react";
import styled from "styled-components"; // Import styled-components
import Failure from '../assets/sorry.webp';

const FailurePage = () => {

  return (
    <LandingPageWrapper>
      <LandingContent>
      </LandingContent>
    </LandingPageWrapper>
  );
};

const mobileBreakpoint = '768px';
const LandingPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-image: url(${Failure});
  background-size: cover;
  box-shadow: inset 0 0 130px 130px black; /* add a black border with a width of 10px */;
  background-repeat: no-repeat;
  background-position: center;
`;

const LandingContent = styled.div`
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 990px;

  h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 20px;
  }

  button {
    background-color: #6f4544;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #6f4544;
    }
  }
`;


 const Button = styled.button`
  font-size: 3.2rem;
  position: flex;
  background-color: red;
  color: red;
  border: none;
  border-radius: 45px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 1rem;
  box-shadow: inset 0 0 2px 3px black;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-text-fill-color: #000000;
  text-color: #bf9b30;
  box-shadow: inset 0 0 2px 3px red;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 10px;
    padding: 2.3rem 0.7rem;
    font-size: 2rem;
    margin-right: 0;
    width: auto; /* Reset width to default */
    display: block; /* Change display to block to stack vertically */
  }
`;

const LandingTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 600;
  font-family: 'Bebas Neue', sans-serif;
  text-align: center;
  color: white;
  margin-bottom: 50px;

  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 10px;
    padding: 0.3rem 0.7rem;
    font-size: 3rem;
    margin-right: 0;
    width: auto; /* Reset width to default */
    display: block; /* Change display to block to stack vertically */
  }
`;

const LandingDescription = styled.h2`
font-size: 1.5rem;
font-weight: 600;
font-family: 'Bebas Neue', sans-serif;
text-align: center;
color: gold;
color: white;
margin-bottom: 150px;

@media (max-width: ${mobileBreakpoint}) {
  margin-top: 10px;
  padding: 0.3rem 0.7rem;
  font-size: 1rem;
}
`;

export default FailurePage;
