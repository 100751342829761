import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import GameBackground from '../../assets/leaderboard.webp';
import axios from "axios";


function LeaderboardPage() {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "api/leaderBoard",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then((response) => {
        const leaderboard = [];

        response.data.forEach((entry, index) => {
          const { account_id, totalBetsPlaced, totalAmountWon } = entry;
          const rank = (index + 1).toString();

          leaderboard.push({
            rank,
            account_id,
            games_played: totalBetsPlaced,
            amount_won: parseFloat(totalAmountWon.$numberDecimal),
          });
        });
        setLeaderboardData(leaderboard);
      });
  }, []);

  return (
    <Container>
      <Leadheader>
        <PopUpTitle>TOP BALLERZ</PopUpTitle>
      </Leadheader>

      {!leaderboardData ? (
        <Loading>Loading leaderboard...</Loading>
      ) : (
        <Table>
          <GameBackgroundImage />
          <thead>
            <tr>
              <th>Rank</th>
              <th>Account ID</th>
              <th>Games Played</th>
              <th>Amount Won</th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.length > 0 ? (
              leaderboardData.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <td>{row.rank}</td>
                    <td>{row.account_id}</td>
                    <td>{row.games_played}</td>
                    <td>{row.amount_won} HBar</td>
                  </TableRow>
                  {index < leaderboardData.length - 1 && (
                    <tr style={{ height: "10px" }}></tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <p>No leaderboard data available.</p>
            )}
          </tbody>
        </Table>
      )}
    </Container>
    );
  }




const PopUpTitle = styled.h2`
  display: absolute;
  font-size: 49px; /* Add 'px' unit */
  
  color: #bf9b30;
  transition: all 0.2s ease;
  font-family: 'Bebas Neue', sans-serif;  
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
`;

const GameBackgroundImage = styled.div`
  margin-top: 120px;
  background-image: url(${GameBackground});
  box-shadow: inset 0 0 42px 42px black;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 768px) {
    margin-top: 70px;
  }
`;


  const Table = styled.table`
  margin: 0 auto;
  border-collapse: collapse;
  margin-top: 45px;
  border-spacing: 0;
  background: transparent;
  font-size: 1.2rem;
  width: 100%;
  color: #bf9b30;
  max-width: 800px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  border-radius: 20px;
  overflow: hidden;
  font-family: 'Bebas Neue', sans-serif;  
  font-weight: bold;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const TableRow = styled.tr`
  background-color: black;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s ease;
  border-radius: 120px;

  &:hover {
    background-color: black;
    cursor: pointer;
  }

  &:last-child {
    border-bottom: none;
  }

  td {
    text-align: center;
    vertical-align: middle;
    font-family: 'Bebas Neue', sans-serif;
    padding: 1rem;
    padding-left: 0.1rem;
  }

  td:first-child {
    text-align: left;
    font-weight: bold;
    padding-left: 2rem;
  }

  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4) {
    font-weight: bold;
  }

  td:nth-child(2) {
    color: #bf9b30;
  }

  td:nth-child(3) {
    color: #bf9b30;
  }

  td:nth-child(4) {
    color: #bf9b30;
  }

  td:nth-child(5) {
    color: #bf9b30;
  }

  @media (max-width: 768px) {
    td {
      padding: 0.8rem;
    }

    td:first-child {
      padding-right: 3.1rem;
    }
  }
`;



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Loading = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  animation: ${fadeIn} 0.5s ease;
  color: #bf9b30;
`;

// Extra styles
const Container = styled.div`
  width: 550px;
  margin: 80px auto;
  background-color: black;

  @media (max-width: 550px) {
    width: 100%;
  }

  @media (max-width: 540px) {
    padding: 0;
  }
`;

const Leadheader = styled.div`
  width: 100%;
  height: 70px;
  background: #c32f82;
  text-align: center;
  box-shadow: inset 0 0 0 10px black; /* add a black border with a width of 10px */
  border-radius: 25px 25px 0 0;
`;






export default LeaderboardPage;
