import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Logo = ({ onClick }) => {
  const handleLogoClick = () => {
    onClick && onClick();
  };

  return (
    <LogoContainer>
      <LogoText>
        <Link to="/" onClick={handleLogoClick}>
          HI/LO BALLERZ
        </Link>
      </LogoText>
    </LogoContainer>
  );
};

const mobileBreakpoint = '768px';


const LogoText = styled.h3`
  font-size: 35px;
  color: #bf9b30;
  transition: all 0.2s ease;

  color: transparent;
  padding: 2rem;
  -webkit-background-clip: text;
  background-clip: text;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #777777;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 42%;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 64em) {
    font-size: 15px;
    margin-right: 40px;
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0.5rem;
    position: static;
    text-align: center;
  }
`;




export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Add padding for spacing */

  /* Optional: Set a max width if you want to limit the logo's size */
  max-width: 100%; /* Adjust this if needed */

  @media screen and (max-width: 767px) {
    padding: 10px; /* Adjust padding for smaller screens */
  }
`;


export default Logo;
