
import styled from 'styled-components';
import CardBack from '../assets/RCgamecard.webp';
import GameBackground from '../assets/backgroundtrue.webp';
import Jackpot from '../assets/nfts_moedas.webp';
import CloseButtonImage from '../assets/close.webp';

import WonImage from '../assets/won.webp';
import LostImage from '../assets/lost.webp';
import HBARLogo from '../assets/hbar.webp';

const mobileBreakpoint = '768px';
const tabletBreakpoint = '1024px';


export const PlaybackWidget = styled.div`
margin-top: 20px;
display: flex;
flex-direction: column;
align-items: center;
gap: 10px;
text-align: center;
`;

export const AudioControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

export const FixedWidthAudio = styled.audio`
  width: 200px; /* Set the desired width for the audio controls */
`;

export const AudioPlayer = styled.audio`
width: 35%;

@media (max-width: 768px) {
  width: 60%;
}
`;

export const TransparentButton = styled.button`
background: transparent;
cursor: pointer;

`;

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;

  @media (max-width: ${mobileBreakpoint}) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Make the container take the full height of the viewport */
    padding: 20px;
  }
`;

export const Popup = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: black;
border-radius: 8px;
box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
padding: 2rem;
max-width: 500px;
width: 90%;
box-shadow: inset 0 0 5px 5px black; /* add a black border with a width of 10px */
max-height: 80%;
overflow: auto;

  @media (max-width: ${mobileBreakpoint}) {
    padding: 1rem;
  }
`;

export const PopupContainerLoss = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: black;
border-radius: 8px;
box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
padding: 2rem;
max-width: 500px;
width: 90%;
box-shadow: inset 0 0 5px 5px black; /* add a black border with a width of 10px */
max-height: 80%;
overflow: auto;

  @media (max-width: ${mobileBreakpoint}) {
    padding: 1rem;
  }
`;

export const PopupContainerJackpotPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9999;
  background-image: url(${Jackpot});
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: ${mobileBreakpoint}) {
    padding: 1rem;
  }
`;

export const CloseButton = styled.button`
  padding: 0.5rem;
  cursor: pointer;
  background-image: url(${CloseButtonImage});
  background-size: cover;
  background-color: black;
  border: none;
`;

export const Won = styled.div`
  position: fixed;
  bottom: 2;
  right: 0;
  margin-right: 30px;
  margin-top: 5px;
  width: 55px;
  height: 55px;
  z-index: 9999;
  background-image: url(${WonImage});
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: ${mobileBreakpoint}) {
    padding: 0.1rem;
    margin-top: 0; /* Adjust margin for smaller screens */
    margin-right: 150px; /* Adjust margin for smaller screens */
    margin-top: 50px; /* Adjust margin for smaller screens */
  }
`;

export const Lost = styled.div`
  position: fixed;
  bottom: 2;
  right: 0;
  margin-right: 30px;
  margin-top: 5px;
  width: 55px;
  height: 55px;
  z-index: 9999;
  background-image: url(${LostImage});
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: ${mobileBreakpoint}) {
    padding: 0.1rem;
    margin-top: 0; /* Adjust margin for smaller screens */
    margin-right: 150px; /* Adjust margin for smaller screens */
    margin-top: 50px; /* Adjust margin for smaller screens */
  }
`;

export const PopupContainerJackpot = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1000px;
  background-color: black;
  transform: translate(-50%, -50%);
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  max-height: 50%;
  overflow: auto;
  border: 6px solid #ffffff;
  border-radius: 50px;
  box-shadow: inset 0 0 10px 10px white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

`;

export const PopupTitleJackpot = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Bebas Neue', sans-serif;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
  color: white;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: ${mobileBreakpoint}) {
    font-size: 1.2rem;
  }
`;

export const PopupHeader = styled.div`
  
display: flex;
justify-content: space-between;
align-items: center;
box-shadow: inset 0 0 0 10px black; /* add a black border with a width of 10px */

background-color: #bf9b30;
margin-bottom: 1rem;
background: black;
padding: 1rem 2rem;
border-radius: 25px 25px 0 0;  
font-family: 'Bebas Neue', sans-serif;
font-weight: bold;
text-transform: uppercase;
text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
-webkit-text-fill-color: #000000;

  @media (max-width: ${mobileBreakpoint}) {
    font-size: 1rem;
  }
`;

export const PopupTitle = styled.h2`
font-size: 1.5rem;
font-weight: 600;  
font-family: 'Bebas Neue', sans-serif;
font-weight: bold;
text-transform: uppercase;
text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
-webkit-text-fill-color: #000000;

  @media (max-width: ${mobileBreakpoint}) {
    font-size: 1.2rem;
  }
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column; /* Stack content vertically on mobile */
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 0 0 10px black;
  background-color: #bf9b30;
  margin-bottom: 1rem;
  background: black;
  padding: 1rem 2rem;
  border-radius: 25px 25px 0 0;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  @media (max-width: ${mobileBreakpoint}) {
    font-size: 0.7rem;
    padding: 2.7rem 1rem; /* Adjust padding for smaller screens */
    border-radius: 15px 15px 0 0; /* Adjust border radius */
  }
`;

export const InstructionsContainer = styled.div`
  margin-top: 2rem;
  max-width: 700px;
  padding: 2rem;
  color: #bf9b30;  
  background-color: black;
  font-family: 'Bebas Neue', sans-serif;
  border-radius: 40px;
  border: 3px solid red;
  background: black;

  h2 {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    font-weight: bold;
    color: #bf9b30;
  }

  ol {
    margin-left: 2rem;
    padding-left: 1rem;

    li {
      margin-bottom: 0.5rem;
      list-style-type: disc;
      margin-left: 0.5rem;
    }
  }

  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;

  .instructions, .card-values {
    flex: 1;
    margin-left: 30px;
  }

  @media (max-width: ${mobileBreakpoint}) {
    max-width: 100%;
    padding: 1rem;
    margin: 2rem 0;
  }
`;

export const CashOutContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;  
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  @media (max-width: ${mobileBreakpoint}) {
    margin-left: 0; /* Adjust margin or positioning for mobile layout */
    justify-content: center; /* Adjust alignment for mobile */
    text-align: center; /* Center text content on mobile */
    padding: 15px; /* Add padding for spacing */
  }
`;

export const HigherLowerContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  border-radius: 115px;
  text-align: center; /* Center text content */
  
  @media (max-width: ${mobileBreakpoint}) {
    margin-bottom: 10px; /* Adjust margin for smaller screens */
    justify-content: center; /* Center content horizontally */
    margin-right: 14px;
  }
`;

export const PlayerCardContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  border-radius: 115px;
  position: center;

  @media (max-width: ${mobileBreakpoint}) {
    margin-left: 0;
    margin-bottom: 10px;
    justify-content: space-between;
    width: 129%;
  }
`;

export const PlayerCardContainer1 = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  border-radius: 115px;
  text-align: center; /* Center text content */
  
  @media (max-width: ${mobileBreakpoint}) {
    margin-bottom: 10px; /* Adjust margin for smaller screens */
    justify-content: center; /* Center content horizontally */
    margin-right: 9px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;  
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  h1 {
    font-size: 3rem;
    margin: 0;
    margin-right: 7rem;
    margin-bottom: 3rem;
    background: black;
    color: #bf9b30;
  }

  @media (max-width: ${mobileBreakpoint}) {
    h1 {
      margin-right: 2rem;
    }
  }
`;

export const BetInputContainer = styled.div`
  display: flex;
  align-items: center;
  color: #bf9b30;  
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 10px;
    padding: 0.3rem 0.7rem;
    font-size: 1rem;
    margin-right: 0;
    text-align: center; /* Center the text */
    width: auto;
    position: center;
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
  }
`;

export const InstructionsButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  background-color: #bf9b30;
  color: red;
  margin-top: 15px;
  align-items: center;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-left: 1rem;
  box-shadow: inset 0 0 2px 3px black;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 10px;
  }
`;



export const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  background-color: #bf9b30;
  color: red;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 10px;
  box-shadow: inset 0 0 2px 3px black;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${mobileBreakpoint}) {
    font-size: 1rem;
    width: 100%; /* Take full width on mobile */
    margin: 5px 0; /* Adjust margin for spacing */
  }
`;


export const ButtonConfirm = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  margin-left: 10px;
  position: flex;
  background-color: #bf9b30;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: inset 0 0 2px 3px black;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 10px;
    margin-right: 4px;
    width: 100%; /* Allow the button to take full width on mobile */
    margin-left: 0; /* Reset the left margin on mobile */
    display: block; /* Allow the button to stack vertically on mobile */
    
  font-size: 0.9rem;
  }
`;

export const ButtonJackpot = styled.button`
  padding: 0.5rem 1rem;
  font-size: 2.2rem;
  position: flex;
  background-color: #bf9b30;
  color: black;
  margin-top: 25px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 1rem;
  box-shadow: inset 0 0 2px 3px black;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  &:hover {
    transform: scale(1.1);
  }

  
  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0.2rem 0.6rem; /* Adjust padding for smaller size */
    font-size: 1.2rem; /* Adjust font size for smaller size */
  }
`;

export const GameOverContainer = styled.div`
  text-align: center;
  margin-top: 3rem;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
    margin-top: 5rem;
  }

  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 2rem;

    h2 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
      margin-top: 2rem;
    }
  }
`;

export const GameControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 1px;
  border-radius: 20%;
  box-shadow: 5px 10px 10px 10px rgba(232, 22, 0, 0.75);
  width: 360px;
  height: 270px;
  background: black;

  & > * {
    background-color: transparent;
  }

  @media (max-width: ${mobileBreakpoint}) {
    background: none; /* Remove background on mobile */
    box-shadow: none; /* Remove box shadow on mobile */
    margin-left: 20px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 224px;
  margin: 20px 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 4rem;
  font-weight: bold;
  color: #000;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${CardBack});
  box-shadow: inset 0 0 2px 2px black;
  transition: transform 0.5s, background-image 0.5s;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;

  &.flipped {
    transform: rotateY(360deg);
  }

  &:hover {
    transform: translateY(-10px);
  }

  .card-front {
    backface-visibility: hidden;
  }

  .card-back {
    background-image: url(${CardBack});
  }

  .previous-card {
    background-image: url(${CardBack});
  }

  .card-back.disabled .front {
    display: none;
  }

  .CardContainer.flipped {
    background-image: url(${CardBack});
    transform: rotateY(180deg);
  }

  .CardContainer.flipped .card-value,
  .CardContainer.flipped .card-suit {
    display: none;
  }

  @media screen and (max-width: 768px) {
    
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
    width: 140px;
    height: 200px;
    margin: 10px;
    font-size: 3rem;
  }
  
`;


const spaceBetween = 'space-between';

export const CardHistory = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
`;

export const BetLabel = styled.p`
  font-weight: bold;
  font-family: 'Bebas Neue', sans-serif;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
  font-size: 1.8rem; /* Adjust font size */
  margin-left: 37px; /* Remove the margin on desktop */


  @media (max-width: ${tabletBreakpoint}) {
    margin-left: 27px; /* Remove the margin on desktop */
  }
  @media (max-width: ${mobileBreakpoint}) {
    margin-right: 0; /* Remove the margin on desktop */
  }
`;

export const BetLabel1 = styled.p`
font-weight: bold;
font-family: 'Bebas Neue', sans-serif;
font-weight: bold;
text-transform: uppercase;
text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
-webkit-text-fill-color: #000000;
font-size: 1.8rem; /* Adjust font size */



@media (max-width: ${mobileBreakpoint}) {
  font-size: 1.5rem; /* Bigger font size for mobile */
  
}
`;

export const CardWrapper = styled.div`
  display: left;
  align-items: ${spaceBetween};
  justify-content: ${spaceBetween};
  margin-right: 15px;
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: ${tabletBreakpoint}) { /* Define your tablet breakpoint */
    margin-right: 0;

    .left-cards,
    .right-cards {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .right-cards {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: ${mobileBreakpoint}) { /* Define your mobile breakpoint */
    margin-right: 0;

    .left-cards,
    .right-cards {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .right-cards {
      margin-top: 10px;
    }

    .left-cards,
    .right-cards {
      transform: none !important; /* Reset the transform for mobile */
    }
  }

  .left-cards {
    display: flex;
    flex-direction: row;
    justify-content: ${spaceBetween};
    transition: transform 0.5s;
    position: relative;
  }

  &:hover .left-cards {
    transform: translateX(-10px);
  }

  .right-cards {
    display: flex;
    justify-content: ${spaceBetween};
    margin-top: 1px;
    transition: transform 0.5s;
    position: relative;
  }

  &:hover .right-cards {
    transform: translateX(10px);
  }
`;


export const GameBackgroundImage = styled.div`
  background-image: url(${GameBackground});
  box-shadow: inset 0 0 42px 42px black;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  margin-top: 50px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 0;
    background-position: center left 73%; 
  }
`;


export const HBARLogoImage = styled.img`
  margin-left: 185px;
  width: 55px;
  height: 55px;
  z-index: 9999;
  background-image: url(${HBARLogo});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
  border-radius: 50%;
  border: 2px solid white;

  @media (max-width: ${mobileBreakpoint}) {
    margin-left: 0;
    margin-top: 10px;
    align-self: center;
  }
`;

export const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  margin-top: 1rem;

  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 2rem;
  }
`;