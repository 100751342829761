import { HashConnect } from "hashconnect";
import { TransferTransaction, AccountId, Transaction } from "@hashgraph/sdk";
import axios from "axios";
import { HederaNetwork} from '@bladelabs/blade-web3.js';
import {BladeConnector, ConnectorStrategy} from '@bladelabs/blade-web3.js';

const network = process.env.REACT_APP_NETWORK || 'mainnet';
const hashconnect = new HashConnect();


const appMetadata = {
    name: "HILO BALLERZ",
    description: "HILO BALLERZ Card Game",
    icon: "https://mydapp.com/logo.png",
  };

  const bladeConnector = new BladeConnector(
    ConnectorStrategy.AUTO, // preferred strategy is optional 
    { // dApp metadata options are optional, but are highly recommended to use
      name: "Awesome DApp",
      description: "DApp description",
      url: "https://awesome-dapp.io/",
      icons: ["some-image-url.png"]
    }
  );

  const params = {
    network: network || HederaNetwork.Mainnet,
    dAppCode: "HILO BALLERZ Card Game",
    icon: "https://mydapp.com/logo.png",
  };



  
  export const connectWalletBlade = async () => {
  
    try {
      const pairedAccountIds = await bladeConnector.createSession(params);
      const bladeSigner = await bladeConnector.getSigner();
      
      const accountIdInit =  await bladeSigner.getAccountId();
      const accountId= "0.0."+ accountIdInit.num.low;
      const walletObject = {
        account_id: accountId.toString(),
      };
  
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/connect-wallet`, walletObject);
      if(response.data.message === "User already Connected"){
        return("User already Connected");
      }else{
        window.localStorage.setItem("access_token", response.data.token);
        window.localStorage.setItem("wallet", accountId);
        return accountId;
      }

    } catch (error) {
      return Promise.reject(false);
    }
  };



 

  async function connectWallet(pairingData) {
    try {
      const walletObject = {
        account_id: pairingData.accountIds[0]
      };
  
      const response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/connect-wallet',
        walletObject
      );
  
      if (response.data.message === "User already Connected") {
        return "User already Connected";
      } else {
        window.localStorage.setItem("access_token", response.data.token);
        window.localStorage.setItem("topic", pairingData.topic);
        window.localStorage.setItem("wallet", pairingData.accountIds[0]);
        return pairingData.accountIds[0];
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  export const connectWalletHashPack = async () => {
    try {
      const initData = await hashconnect.init(appMetadata, network, false);
      const pairingStringElement = document.getElementById('pairingStringElement');
      if (pairingStringElement) {
        pairingStringElement.innerHTML = initData.pairingString;
      }
      let response={};
      const walletMetadataPromise = new Promise((resolve) => {
        hashconnect.foundExtensionEvent.once((walletMetadata) => {
          hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
          resolve(walletMetadata);
        });
      });
    
      // Generate a QR code URL from the pairing string
      const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(initData.pairingString)}`;

      // Display the QR code
      const qrCodeContainer = document.getElementById('qrCodeContainer');
      if (qrCodeContainer) {
        qrCodeContainer.innerHTML = `<img src="${qrCodeUrl}" alt="QR Code">`;
      }
      const pairingDataPromise = new Promise((resolve) => {
        hashconnect.pairingEvent.once((pairingData) => {
          resolve(pairingData);
        });
      });
    
      const [walletMetadata, pairingData] = await Promise.all([
        walletMetadataPromise,
        pairingDataPromise
      ]);
      let result="";
      if (walletMetadata && pairingData) {
        result=await connectWallet(pairingData);
      }
      response={result:result};
      return response;
    } catch (error) {
      console.error("Error connecting to wallet:", error);
      const initData = await hashconnect.init(appMetadata, network, false);
      return {pairingString:initData.pairingString};
    }
  };

  
  


  export const disconnectWallet = async (walletType) => {
    const walletObject = {
        account_id: window.localStorage.getItem("wallet")
    };

    try {
        const response = await axios.post(
            process.env.REACT_APP_API_URL + 'api/disconnect-wallet',
            walletObject,
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` }
            }
        );

        window.localStorage.setItem("wallet", null);
        window.localStorage.setItem("hashconnectData", null);
        window.localStorage.setItem("walletType", null);
        window.localStorage.setItem("access_token", null);
        window.localStorage.setItem("topic", null);

        if (walletType === "hashpackwallet") {
            hashconnect.disconnect(appMetadata, network, false);
        } else if (walletType === "bladewallet") {
            await bladeConnector.killSession();
        }

        return true;
    } catch (error) {
        return false;
    }
};




    export const sendDepositBlade = async (depositAmount, network) => {
      try {
        const bladeSigner = await bladeConnector.getSigner();
        const senderAccountId = localStorage.getItem("wallet");
        const receiverAccountId = process.env.REACT_APP_ACCOUNT_ID;

        const transaction = await new TransferTransaction()
            .addHbarTransfer(bladeSigner.getAccountId(), -depositAmount)
            .addHbarTransfer(receiverAccountId, depositAmount)
            .freezeWithSigner(bladeSigner);

        const signedTransaction = await bladeSigner.signTransaction(transaction);
        const result = await signedTransaction.executeWithSigner(bladeSigner);
    
        
        return [result, senderAccountId+ "@"+result.transactionId.validStart.seconds.low +"."+ result.transactionId.validStart.nanos.low];;
      } catch (error) {
        console.error("Error signing transaction:", error);
        return false;
      }
    };
    
    
    
    
    
    
    export const sendDepositHashPack = async (depositAmount) => {
    
      try {
        
          const topic = localStorage.getItem('topic');
          const senderAccountId = localStorage.getItem('wallet');
          const receiverAccountId = process.env.REACT_APP_ACCOUNT_ID;
            const provider = hashconnect.getProvider(network,topic,senderAccountId);
  
          const signer = hashconnect.getSigner(provider);
  
          let transaction =await new TransferTransaction()
          .addHbarTransfer(AccountId.fromString(senderAccountId), -depositAmount)
          .addHbarTransfer(AccountId.fromString(receiverAccountId), depositAmount)
          .freezeWithSigner(signer)
  
          let result = await transaction.executeWithSigner(signer)
          return result;
         } catch (error) {
          return false;
      }
    };
    
  