import React, { useState , useEffect,useContext  } from "react";
import styled, { keyframes } from "styled-components";
import { BalanceContext } from '../BalanceProvider';
import {sendDepositBlade, sendDepositHashPack } from '../hashConnect';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CloseButtonImage from '../assets/close.webp';



const Bank = ({ show, onClose }) => {
  const { balance, setBalance } = useContext(BalanceContext);
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState(null);
  const [BankData, setBankData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [confirmOpen, setConfirmOpen] = useState(false);


  
  const sendDepositReq = async ( amountReq, transactionIdReq ) => {
    const depositObject = {
      account_id_from: window.localStorage.getItem("wallet"),
      amountReq: amountReq,
      transactionIdReq: transactionIdReq,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "api/deposit", depositObject, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
      .then((response) => {
        setBalance(parseFloat(response.data.balance).toFixed(2));
        setTimeout(() => {
          onClose(); // Close the Bank component after 2 seconds
        }, 2000);
      });
  } 
  
  
  const handleDeposit = async () => {
    const depositAmount = parseInt(amount); // Convert amount to a number
  
    if (isNaN(depositAmount) || depositAmount <= 0) {
      setError("Please enter a valid deposit amount.");
      return;
    }
  
    if (depositAmount > 2000) {
      setError("Deposit amount cannot exceed 2000.");
      return;
    }
  
    setLoading(true);

    const walletType = localStorage.getItem('walletType');
    
    let result;
    let transactionId;
    
    if (walletType === 'bladewallet') {
      const resultReq = await sendDepositBlade(depositAmount);
      result = resultReq[0];
      transactionId =resultReq[1];
    } else if (walletType === 'hashpackwallet') {
      result = await sendDepositHashPack(depositAmount);
      transactionId=result.transactionId;
    } else {
      throw new Error("Unknown wallet type");
    }
  

    if (result) {
      sendDepositReq(depositAmount, transactionId);
      setSnackbarMessage(`You deposited ${depositAmount} hbar`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    }
  };
  
  
  
  

  const sendWithdraw = async (amountReq) => {
    setLoading(true);
    const withdrawObject = {
      accountId: window.localStorage.getItem("wallet"),
      amountReq: amountReq
    };

    axios.post(process.env.REACT_APP_API_URL + "api/withdraw", withdrawObject, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })

      .then((response) => {

        setBalance(parseFloat(balance - amountReq).toFixed(2));
        setAmount(0);
        setLoading(false);
        onClose(); // Close the Bank component
        // Show success notification
        setSnackbarMessage(`Withdrawal successful`);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          window.location.reload();
          // Show success notification for wallet disconnection
          setSnackbarMessage('Wallet already connected');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          return;
        } else {
          console.log(error);
          setLoading(false);
          // Show error notification
          setSnackbarMessage(`Withdrawal failed: ${error.message}`);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }

      });
  };



  
  const handleWithdraw = async () => {
    const withdrawAmount = parseInt(amount); // Convert amount to a number
  
    if (isNaN(withdrawAmount) || withdrawAmount <= 0) {
      setError("Amount must be a valid whole number greater than 0");
      return;
    }
  
    if (withdrawAmount > balance) {
      setError("Insufficient balance");
      return;
    }
  
    if (withdrawAmount > 2000) {
      setError("Withdrawal limit is 2000 HBAR");
      return;
    }
  
    setConfirmOpen(true);
  };
  
  // Rest of the code...
  
  
  
  const confirmWithdraw = async () => {
    setConfirmOpen(false);
  
    try {
      await sendWithdraw(amount);
      setSnackbarMessage(`You withdrew ${amount} hbar`);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.log(error);
      setSnackbarMessage(`Withdrawal failed: ${error.message}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  
  const cancelWithdraw = () => {
    setConfirmOpen(false);
  };
  

  useEffect(() => {
    // Simulate API delay to show loading animation
    setTimeout(() => {
      setBankData(true);
    }, 2000);
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  
  return (
    <>
      {show && (
        <>
          <PopupContainer>
            <Popup>
              {!BankData ? (
                <Loading>Loading Bank...</Loading>
              ) : (
                <>
                  <PopupHeader>
                    <PopupTitle>Bank System</PopupTitle>
                    <PopupTitle> | </PopupTitle>
                    <PopupTitle>Balance: {balance} (hbar)</PopupTitle>
                    <CloseButton onClick={onClose}></CloseButton>
                  </PopupHeader>
                  <PopupContent>
                    {error && <PopupError>{error}</PopupError>}
                    <Input
                      type="number"
                      placeholder="Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <PopupContent>Withdraw Fee is 2% (Total of HBAR being liquidated)</PopupContent>
                    <ButtonWrapper>
                      <Button onClick={handleDeposit}>Deposit</Button>
                      <Button onClick={handleWithdraw}>Withdraw</Button>
                    </ButtonWrapper>
                  </PopupContent>
                </>
              )}
            </Popup>
          </PopupContainer>
  
          {/* Withdraw confirmation dialog */}
          <Dialog open={confirmOpen} onClose={cancelWithdraw} style={{ zIndex: 10000 }}>

            <DialogContent>
            <DialogTitle sx={{ color: "black" }}>Are you sure you want to withdraw {amount} hbar?</DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelWithdraw}>Cancel</Button>
              <Button onClick={confirmWithdraw} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
  
          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "450%" }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
  
  
  
  }


const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`;

const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  box-shadow: inset 0 0 5px 5px #bf9b30; /* add a black border with a width of 10px */
  max-height: 80%;
  overflow: auto;
`;

const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const PopupTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Bebas Neue', sans-serif;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
  color: white ;
  text-align: left;
`;

const PopupError = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Bebas Neue', sans-serif;
  color: white ;
  text-align: left;
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Bebas Neue', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 5px;
  border: none;
  background-color: #003f20;
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  flex-grow: 1;
  text-align: center;

  &:hover {
    background-color: #3e8e41;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  animation: ${fadeIn} 0.5s ease;
  color: #f5f5f5;
  font-family: 'Bebas Neue', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #bf9b30, 1px -1px 0 #bf9b30, -1px 1px 0 #bf9b30, 1px 1px 0 #bf9b30;
  -webkit-text-fill-color: #000000;
`;

const CloseButton = styled.button`
  padding: 0.5rem;
  cursor: pointer;
  background-image: url(${CloseButtonImage});
  background-size: cover;
  background-color: transparent;
  border: none;

`;


export default Bank;